import React from "react";
import { Route, Switch } from "react-router-dom";
import "./App.css";
import MainScreen from "./screens/Main.Screen";
import { BrowserView, MobileView } from "react-device-detect";
import Logo from "./svg/logo.svg";
import QR from "./svg/qr-code.svg";
import GooglePlayLogo from "./img/google-play-badge.png";

function App() {
  return (
    <div className="App">
      <BrowserView>
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            minHeight: "95vh",
            flexDirection: "column",
          }}
        >
          <img width={150} src={Logo} alt="" />
          <p style={{ padding: 20, textAlign: "center" }}>
            Please scan the QR code using your android device to install the
            MegaPlay app
          </p>
          <div
            style={{
              backgroundColor: "white",
              borderRadius: "50%",
              padding: 45,
            }}
          >
            <img height={200} alt="" src={QR} />
          </div>
          <div style={{ padding: 10 }}>
            <img height={90} alt="" src={GooglePlayLogo} />
          </div>
        </div>
      </BrowserView>
      <MobileView>
        <Switch>
          <Route component={MainScreen} path="/" exact />
        </Switch>
      </MobileView>
    </div>
  );
}

export default App;
