import React, { useState } from "react";
import { Game } from "../interfaces/Game.Interface";
import "./GameBanner.Styles.css";

interface Props {
  games: Game[];
  elem: number;
  width: string;
  type: "img" | "div";
  reverse?: boolean;
  style?: any;
}
const radius = {
  borderRadius: "10px",
};

export default function GameBannerElement(props: Props) {
  const [touched, setTouched] = useState(false);
  const { games, elem, width, type, reverse } = props;

  const toggleTouched = () => {
    setTouched((prev) => !prev);
  };

  const onMouseUp = () => {
    setTimeout(() => {
      setTouched(false);
    }, 250);
  };

  if (type === "img") {
    return (
      <img
        className={touched ? "touched" : ""}
        onMouseDown={toggleTouched}
        onMouseUp={onMouseUp}
        width={width}
        src={games[elem] ? games[elem].platforms[1].languages[0].iconURL : ""}
        alt=""
        style={{ ...props.style, ...radius }}
        onClick={() =>
          (window.location.href =
            games[elem].platforms[1].languages[0].resolutions[0].gameURL)
        }
      />
    );
  }
  return (
    <div
      className={touched ? "touched" : ""}
      onMouseDown={toggleTouched}
      onMouseUp={onMouseUp}
      style={{
        margin: 10,
        marginRight: !reverse ? 0 : 10,
        marginLeft: !reverse ? 10 : 0,
        height: "100%",
        backgroundImage: games[elem]
          ? "url('" +
            encodeURI(games[elem].platforms[1].languages[0].bannerURL) +
            "')"
          : "",
        backgroundSize: "cover",
        backgroundPosition: "center",
        ...radius,
      }}
      onClick={() =>
        (window.location.href =
          games[3].platforms[1].languages[0].resolutions[0].gameURL)
      }
    ></div>
  );
}
