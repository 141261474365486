import React from "react";
import { Game } from "../interfaces/Game.Interface";
import GameBannerElement from "./GameBanner.Element";

interface Props {
  games: Game[];
  reverse?: boolean;
}

export default function GameBannerCollectionElement(props: Props) {
  const { games, reverse } = props;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: reverse ? "row-reverse" : undefined,
        paddingLeft: 10,
        paddingRight: 10,
        overflow: "hidden",
      }}
    >
      <div style={{ flex: 1 }}>
        <div>
          <GameBannerElement
            elem={0}
            games={games}
            reverse={reverse}
            type="img"
            width="99%"
          />
        </div>
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            display: "flex",
            flex: 1,
            paddingLeft: !reverse ? 10 : undefined,
            paddingRight: reverse ? 10 : undefined,
          }}
        >
          <GameBannerElement
            elem={1}
            games={games}
            reverse={reverse}
            type="img"
            width="48%"
          />

          <GameBannerElement
            elem={2}
            games={games}
            reverse={reverse}
            type="img"
            width="48%"
            style={{
              marginLeft: 10,
              marginRight: 10,
            }}
          />
        </div>
        <GameBannerElement
          elem={3}
          games={games}
          reverse={reverse}
          type="div"
          width="48%"
        />
      </div>
    </div>
  );
}
