import axios from "axios";
import React, { useEffect, useState } from "react";
import GameBannerCollectionElement from "../components/GameBannerCollection.Element";
import { FREE_TO_PLAY } from "../constants/urls";
import { Game } from "../interfaces/Game.Interface";
import Logo from "../svg/logo.svg";

export default function MainScreen() {
  const [games, setGames] = useState<Game[]>([]);

  useEffect(() => {
    axios
      .get(FREE_TO_PLAY)
      .then((res) => {
        setGames(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const renderBanners = () => {
    let i,
      j,
      k = 0,
      temparray,
      chunk = 4;
    let comp = [];
    for (i = 0, j = games.length; i < j; i += chunk) {
      temparray = games.slice(i, i + chunk);
      comp.push(
        <GameBannerCollectionElement
          reverse={k % 2 === 1}
          key={i}
          games={temparray}
        />
      );
      k++;
    }

    return comp;
  };

  return (
    <div>
      <div style={{ padding: 10 }}>
        <img width={90} src={Logo} alt="" />
        <p style={{ fontSize: 23, fontWeight: "bold" }}>Hello player</p>
      </div>
      <div style={{ paddingBottom: 20 }}>{renderBanners()}</div>
    </div>
  );
}
